:root {
  height: 100vh !important;
}

body {
  font-family: Arial, Helvetica, sans-serif !important;
}

::-webkit-scrollbar {
  display: none;
}

.add-campaign {
  width: 60%;
  border: 1px solid grey;
  padding: 30px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.bot-card {
  height: 200px;
  width: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
  border-radius: 5px;
  flex-wrap: wrap;
  background-color: #ffff;
  box-shadow: 0 10px 20px #d7e7eb, 0 6px 6px #076d81;
}

.bot-list-container {
  /* display: flex;
  width: 100%;
  flex-wrap: wrap; */
}

.botNameLabel {
  font-size: 25px;
  font-weight: 600;
  color: #050505;
}

.start-card {
  min-width: 300px;
  background-color: #dedede;
  border: 1px solid #076d81;
}

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.main-body {
  background-color: #ffffff;
  display: flex;
  height: 92%;
}

.bot-list {
  background-color: #fcfcfc;
  display: flex;
  height: 92%;
}

.start-item-card-body {
  background-color: rgb(212, 202, 202);
  box-shadow: rgba(39, 39, 39, 0.651) 0px 2px 8px 0px;
  display: flex;
  padding: 5px;
}

.btn-secondary {
  border: 2px solid #2290a7;
  background-color: #2290a7 !important;
  color: #f0f4f5;
  font-weight: 600;
  padding: 2px 15px;
  border-radius: 5px;
  width: fit-content;
}

.start-item-card-header {
  background-color: black;
  color: white;
}

.blade {
  min-width: 200px;
  /* max-width: 200px; */
  display: flex;
  flex-direction: column;
  font-size: 0.9rem;
  /* border: 1px solid #076d81; */
  /* height: 20%; */
  margin-top: 10px;
}

.last-blade {
  max-width: 2000px;
  position: relative;
  flex: 1;
}

.blade-body {
  background-color: #fafafa;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  height: 100%;
}

.blade-body-items {
  display: flex;
  background-color: #fafafa;
  flex-direction: column;
  padding: 10px;
  height: 94%;
}

.hoverable-btn:hover {
  background-color: #efefef !important;
}

.blade-body-actions {
  display: flex;
  flex-direction: column;
  padding: 0px;
}

.blade-header {
  background-color: #076d81;
  color: #fbfeff;
  font-weight: 500;
  height: 32px;
  line-height: 25px;
  vertical-align: middle;
}

.box {
  border: 3px solid #666;
  background-color: #ddd;
  border-radius: 0.5em;
  padding: 10px;
  cursor: move;
}

.ai-bot-card {
  border: 1px solid #d7d3d3;
  margin-bottom: 10px;
  margin-top: 5px;
  background-color: #fff;
  /* border-radius: 5px; */
}

.small-font {
  font-size: 12px;
}

.form {
  margin: 10px;
}

.ai-bot-card-header {
  background-color: #ebeaea;
  text-align: center;
  line-height: 25px;
  font-weight: 500;
  vertical-align: middle;
  height: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 4px;
  padding-right: 4px;
  /* border-radius: 5px 5px 0px 0px; */
}

.ai-bot-card-body {
  background-color: white;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  padding-top: 10px;
  /* border-radius: 0px 0px 5px 5px; */
}

.ai-bot-action-card-header {
  /* background-color: #fafafa; */
  text-align: center;
  line-height: 25px;
  font-weight: 500;
  vertical-align: middle;
  height: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 4px;
  padding-right: 4px;
  /* border-radius: 5px 5px 0px 0px; */
}

.ai-bot-action-card-body {
  /* background-color: white; */
  padding-bottom: 10px;
  /* padding-top: 10px; */
  /* border-radius: 0px 0px 5px 5px; */
}

.ai-bot-single-line-label {
  width: 100%;
  font-size: 0.9rem;
  font-weight: 500;
  /* text-align: justify; */
  padding-right: 5px;
}

.ai-bot-single-line-text {
  width: 100%;
  border-radius: 4px;
  padding: 5px;
  font-size: 0.9rem;
}

.ai-bot-action-header-text {
  width: 100%;
  border-radius: 4px;
  font-weight: 600;
  font-size: 1rem;
  color: #033a45;

  /* background-color: rgb(209, 201, 201); */
}

.ai-bot-list-item {
  width: 100%;
  border-radius: 4px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 0.9rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.ai-bot-action-item {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 0.9rem;
  align-items: center;
  border-bottom: 1px solid #ddd;
}

.ai-bot-action-item:hover,
.ai-bot-action-item:hover svg path {
  width: 100%;
  background-color: #dad6d6;
  /* color: white; */
  cursor: pointer;
}

.transparent-input {
  background: rgba(255, 255, 255, 0.534);
  border: none;
  display: block;
  outline: none;
  width: 100%;
  height: 40px;
  padding: 10px;
  font-weight: 600;
  color: #080808;
  -webkit-box-shadow: 0 2px 10px 1px rgba(0, 0, 0, 0.5);
  box-shadow: 0 2px 10px 1px rgba(0, 0, 0, 0.5);
}

.btn:hover {
  cursor: pointer;
}
.text-bold {
  font-weight: bold;
}
.icon:hover {
  cursor: pointer;
}

.close-blade {
  color: white;
}

.close-blade:hover {
  cursor: pointer;
  font-size: larger;
}

.action-buttons {
  color: black;
}

.action-buttons:hover {
  cursor: pointer;
  font-size: larger;
}

.scrollable-div {
  height: 100%;
  overflow-y: scroll;
}

.dropdown-menu {
  background-color: white !important;
  font-size: 14px;
}

.ai-bot-image {
  border: 1px solid #ccc;
  width: 100%;
  height: 120px;
  margin-bottom: 5px;
}

.ai-bot-video {
  border: 1px solid #ccc;
  width: 100%;
  height: auto;
  margin-bottom: 5px;
}

.ai-bot-toggle-btn {
  margin-left: 10px;
  font-size: 20px;
}

.at-bot-non-editable-mode {
  display: flex;
  justify-content: space-between;
}

.start-card-header {
  display: flex;
  align-items: center;
  padding-left: 6px;
  background-color: #efefef;
  color: black;
  height: 25px;
  line-height: 25px;
  align-items: center;
  /* box-shadow: rgba(39, 39, 39, 0.651) 0px 2px 4px 0px; */
  font-size: large;
  font-weight: 600;
  padding: 3px;
}

.start-card-body-content div {
  color: black;
  padding: 4px;
  font-weight: 600;
  line-height: 30px;
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  border: none;
  width: 100%;
}

.action-item-view {
  color: black;
  padding: 4px;
  font-weight: 600;
  line-height: 60px;
  height: 60px;
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  border: none;
  width: 100%;
}

.action-item-view:hover {
  background-color: #aaa;
}

.selected-code,
.selected-code svg path {
  background-color: #076d81;
  color: white;
  cursor: pointer;
  text-decoration: none;
  /* width: 100%; */
  block-size: fit-content;
}

.start-card-body-content div:hover {
  /* color: green; */
  background-color: #ededed;
}

.start-card-body-content div:focus {
  color: #43b02a !important;
  background-color: white !important;
}

.botlist-header {
  font-size: 25px;
  font-weight: 600;
  color: #0da19a;
  margin-left: 10px;
  height: 6%;
  display: flex;
  /* justify-content: center; */
  align-items: center;
}

.creator-header {
  font-size: 30px;
  font-weight: 600;
  color: #076d81;
  margin-left: 10px;
  height: 6%;
  display: flex;
  /* justify-content: center; */
  align-items: center;
}

.btn {
  border-radius: 0px !important;
  /* color: black;
    background-color: green; */
}

.btn-outline {
  color: #076d81;
  border: 2px solid #076d81;
  border-color: #076d81;
  font-weight: 600;
  padding: 4px 15px;
}

.sm-btn-outline {
  color: #076d81;
  border: 2px solid #076d81;
  border-color: #076d81;
  font-weight: 600;
  font-size: 0.8rem;
  padding: 4px 15px;
}

.btn-dark {
  color: #fffefe;
  background-color: #076d81;
  font-weight: 600;
}

.btn-dark:hover {
  color: #fffefe;
  background-color: #076d81;
  font-weight: 600;
}

.btn-outline:hover {
  color: white;
  border-color: #076d81;
  background-color: #076d81;
}

.sm-btn-outline:hover {
  color: white;
  border-color: #076d81;
  background-color: #076d81;
}

input[type="file"]::file-selector-button {
  color: #076d81;
  background-color: white;
  border: 1px solid #076d81;
  cursor: pointer;
  /* border-radius: 15px; */
  font-size: 12px;
}

input[type="file"]::file-selector-button:hover {
  color: white;
  background-color: #076d81;
  border: 1px solid #076d81;
  cursor: pointer;
}

.gear-icon:hover {
  padding: 2px;
  /* border-radius: 50px; */
  border: 1px dotted 076d81;
}

.main-body-content {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #ffffff;
}

.container-card {
  width: 100%;
  height: 100%;
}

.container-card {
  border: 1px solid #999;
  margin-bottom: 10px;
  margin-top: 5px;
  background-color: white;
}

.actions-table {
  margin: 9px;
  padding: 1px;
  border: 1px solid #c8baba;
}

.variable-badges {
  background-color: #3593a6;
  color: rgb(255, 247, 247);
  padding: 2px 0px 5px 5px;
  margin: 5px 0px 0px 5px;
  display: flex;
  font-weight: 500;
  justify-content: space-between;
  width: fit-content;
}

.buttons-container {
  display: flex;
  height: 80%;
  margin-top: 10px;
}

.bot-creator-logout-btn {
  background: #076d81;
  border: none;
  border-radius: 21px;
  box-shadow: 0px 1px 6px #076d81;
  cursor: pointer;
  color: white;
  height: 100%;
  margin-left: 10px;
  margin-right: 10px;
  transition: 0.25s;
  width: 153px;
}

.node-title {
  font-size: 0.8rem;
}

Button {
  text-transform: none !important;
  font-family: "Poppins", sans-serif !important;
}

Table {
  font-family: "Poppins", sans-serif !important;
}

.bot-creator-header {
  display: flex;
  justify-content: space-between;
  height: 6%;
}

.main-heading {
  margin: 10px;
  font-size: 25px;
  padding: 10px 150px;
  color: #076d81;
}

.sub-main-heading {
  font-size: 20px;
  color: #076d81;
  font-weight: 600;
}

.project-name {
  font-size: 30px;
  color: #0e0e0e;
  margin: 5px 10px;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  align-items: center;
  display: flex;
}

.selected {
  border: none;
  background-color: #ffffff !important;
  color: #076d81;
  border: 1px solid rgb(199, 199, 199);
  border-radius: 5px 5px 0 0;
}

.background-theme {
  height: 100vh;
  overflow-y: hidden;
  background: -webkit-linear-gradient(bottom, #076d81, #fffff9);
  background-repeat: no-repeat;
}

label {
  font-family: "Raleway", sans-serif;
  font-size: 11pt;
}

.login-card {
  background: #fbfbfb;
  border-radius: 8px;
  box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.65);
  height: 410px;
  margin: 6rem auto 8.1rem auto;
  width: 329px;
}

.card-content {
  padding: 12px 44px;
}

.card-title {
  font-family: "Raleway Thin", sans-serif;
  letter-spacing: 4px;
  padding-bottom: 23px;
  padding-top: 13px;
  text-align: center;
}

.login-btn {
  background: #076d81;
  border: none;
  border-radius: 21px;
  box-shadow: 0px 1px 8px #076d81;
  cursor: pointer;
  color: white;
  font-family: "Raleway SemiBold", sans-serif;
  height: 42.3px;
  margin: 0 auto;
  margin-top: 50px;
  transition: 0.25s;
  width: 153px;
}

.logout-btn {
  background: #076d81;
  border: none;
  border-radius: 21px;
  box-shadow: 0px 1px 6px #076d81;
  cursor: pointer;
  color: white;
  font-family: "Raleway SemiBold", sans-serif;
  height: 42.3px;
  margin: 10px auto;
  transition: 0.25s;
  width: 153px;
}

.login-btn:hover {
  box-shadow: 0px 1px 18px #076d81;
}

.navbar-menu {
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: space-between;
}

.form {
  align-items: left;
  display: flex;
  flex-direction: column;
}

.form-border {
  background: -webkit-linear-gradient(right, #a6f77b, #076d81);
  height: 1px;
  width: 100%;
}

.form-content {
  background: #fbfbfb;
  border: none;
  outline: none;
  padding-top: 14px;
}

.underline-title {
  background: -webkit-linear-gradient(right, #a6f77b, #076d81);
  height: 2px;
  margin: -1.1rem auto 0 auto;
  width: 89px;
}

.create-bot-card {
  width: 30%;
  border: 1px solid grey;
  padding: 30px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.modal-header {
  background-color: #1976d2;
  color: #ffffff;
  display: flex;
  justify-content: center;
}

.skuList {
  /* border: 1px solid rgb(124, 124, 124); */
  padding: 2px;
  height: 100%;
  overflow: scroll;
}

.new-inventory {
  position: absolute;
  width: 99%;
  height: 95%;
  display: flex;
}

.sub-heading {
  font-weight: 600;
  font-size: 1rem;
  color: #1976d2;
}

.card-border {
  border-radius: 16px !important;
  padding: 0.2rem !important;
  box-shadow: none !important;
  border: 1px solid #bababa;
}

.inventory-card {
  min-height: 60px;
  border: 2px solid #d0cdcd;
  margin-bottom: 10px;
  border-radius: 5px;
  padding: 5px;
  background-color: #ffff;
  /* box-shadow: 0 10px 20px #d7e7eb, 0 6px 6px #076d81; */
}

.user-card {
  min-height: 190px;
  border: 2px solid #d0cdcd;
  min-width: 48%;
  margin-bottom: 10px;
  border-radius: 5px;
  padding: 5px;
  background-color: #ffff;
  /* box-shadow: 0 10px 20px #d7e7eb, 0 6px 6px #076d81; */
}

.design-card {
  min-height: 250px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: 2px solid #d0cdcd;
  background-color: #ffff;
  padding: 5px;
  /* box-shadow: 0 10px 20px #d7e7eb, 0 6px 6px #076d81; */
}

.card-title-header {
  font-size: 0.8rem;
  font-weight: 600;
}

input[type="file"]::file-selector-button {
  color: #1275ff;
  background-color: white;
  border: 1px solid #1275ff;
  cursor: pointer;
  /* border-radius: 15px; */
  font-size: 15px;
  font-weight: 500;
}

input[type="file"]::file-selector-button:hover {
  color: white;
  background-color: #1275ff;
  border: 1px solid #1275ff;
  cursor: pointer;
}

/* 
input[type="file"]:hover {
  background-color: #1275ff;
} */

/* Extra Styles */

.organization-main-body {
  background-color: rgb(246, 245, 245);
  height: 100vh;
  background-color: #ffffff;
  min-height: 100vh;
}

.organization-name {
  font-size: 1.6rem;
  color: #1976d2;
}

.action-item-view {
  color: #666;
  font-weight: 600;
  display: flex;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  border: none;
  width: 100%;
}

.action-item-view-selected,
.action-item-view-selected svg path {
  background-color: #ffffff;
  color: #1275ff;
  cursor: pointer;
  text-decoration: none;
  block-size: fit-content;
}
